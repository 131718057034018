import {createApp} from 'vue'
import App from './App.vue'

import './style/style.scss';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import 'element-plus/theme-chalk/dark/css-vars.css'

import router from './router'
import config from "./config";

import * as Icons from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import mitt from 'mitt'
import VueCookies from 'vue-cookies'

const app = createApp(App)

const Mit = mitt()
declare module "vue" {
    export interface ComponentCustomProperties {
        $Bus: typeof Mit
    }
}
app.config.globalProperties.$Bus = Mit
app.config.globalProperties.$CONFIG = config;

for (const [key, component] of Object.entries(Icons)) {
    app.component(key, component)
}

app.config.globalProperties.$cookies = VueCookies;//全局挂载 $cookies

app.use(router)
    .use(VueCookies)
    .use(ElementPlus,{ locale: zhCn,})
    .mount('#app')


