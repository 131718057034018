import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import config from "@/config";

const routes: Array<RouteRecordRaw> = [

  {
    path: '/login',
    name: 'login',
    meta:{
      title:"登录页"
    },
    component: () => import('../views/login/Index.vue')
  },
  {
    path: '/user_register',
    name: 'user_register',
    meta:{
      title:"注册"
    },
    component: () => import('../views/login/Register.vue')
  },
  {
    path: '/reset_password',
    name: 'reset_password',
    meta:{
      title:"重置密码"
    },
    component: () => import('../views/login/resetPassword.vue')
  },
  {
    path: '/',
    name: 'home',
    meta:{
      title:"首页",
      requireAuth: true, // 判断是否需要登录
    },
    component:()=>import('../views/LayoutView.vue'),
    redirect:'/home',
    children:[
      {
        path: '/home',
        name: 'home',
        component: ()=>import('../views/HomeView.vue'),
        meta:{
          title:"首页-我的工作台",
          menu_name:'首页',
          group:'home',
          requireAuth: true, // 判断是否需要登录
        }
      },
      {
        path: '/courseselecting',
        name: 'courseselecting',
        component: ()=>import('../views/courseselecting/index.vue'),
        meta:{
          title:"选课",
          menu_name:'选课',
          group:'home',
          requireAuth: true, // 判断是否需要登录
        }
      },
      {
        path: '/classgrouping',
        name: 'classgrouping',
        component: ()=>import('../views/classgrouping/index.vue'),
        meta:{
          title:"分班",
          menu_name:'分班',
          group:'home',
          requireAuth: true, // 判断是否需要登录
        }
      },
      {
        path: '/coursesarranging',
        name: 'CoursesArranging',
        component: ()=>import('../views/coursesarranging/middle.vue'),
        meta:{
          title:"中小学排课",
          menu_name:'排课',
          group:'home',
          requireAuth: true, // 判断是否需要登录
        }
      },
      {
        path: '/newcoursesarranging',
        name: 'NewCoursesArranging',
        meta:{
          title:"高中排课",
          menu_name:'高中排课',
          group:'home',
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import('../views/coursesarranging/high.vue')
      },
      {
        path: '/classgrouping',
        name: 'classgrouping',
        component: ()=>import('../views/classgrouping/index.vue'),
        meta:{
          title:"分班",
          menu_name:'分班',
          group:'home',
          requireAuth: true, // 判断是否需要登录
        }
      },
      {
        path: '/groupingview',
        name: 'groupingview',
        component: ()=>import('../views/classgrouping/GroupView.vue'),
        meta:{
          title:"分班视图",
          menu_name:'分班视图',
          group:'home',
          requireAuth: true, // 判断是否需要登录
        }
      },
      {
        path: '/tools/classgrouping',
        name: 'ToolsClassGrouping',
        component: ()=>import('../views/tools/ClassGrouping.vue'),
        meta:{
          title:"分班助手",
          menu_name:'分班助手',
          group:'home',
          requireAuth: true, // 判断是否需要登录
        }
      },
      {
        path: '/tools/gradeshare',
        name: 'ToolsGradeShare',
        component: ()=>import('../views/tools/GradeShare.vue'),
        meta:{
          title:"成绩分享",
          menu_name:'成绩分享',
          group:'home',
          requireAuth: true, // 判断是否需要登录
        }
      },
      {
        path: '/setting',
        name: 'setting',
        meta:{
          title:"配置",
          menu_name:'配置',
          group:'home',
        },
        component: () => import('../views/SettingView.vue')
      },
      {
        path: '/about',
        name: 'about',
        meta:{
          title:"关于",
          menu_name:'关于',
          group:'home',
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import('../views/AboutView.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//路由拦截
router.beforeEach((to,from,next)=> {
  if (to.meta.title) {
    document.title = to.meta.title +" - "+ config.APP_NAME
  }
  if (to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限
    if (localStorage.token) { // 判断当前的token是否存在 ； 登录存入的token
      next();
    } else {
      next({ path: '/login', query: {redirect: to.fullPath}})
    }
    next();
  } else {
    next();
  }
})


export default router
